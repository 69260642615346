import React, { useEffect, useMemo, useState } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";

import { RichText } from "prismic-reactjs";
import SEO from "../components/seo";
import Slider from "../components/slices/Slider";
import Projets from "../components/slices/Projets";
import clsx from "clsx";
import SliderPagination from "../components/slices/SliderPagination";

export const pageQuery = graphql`
  query ProjetBySlug($uid: String!) {
    prismicProjet(uid: { eq: $uid }) {
      data {
        ...projet
      }
    }
  }
`;

const TemplateProjet = ({ data }) => {
  const {
    title,
    texte,
    image_clef,
    sta,
    cat,
    abc,
    lieu,
    phase,
    date,
    fiche_technique_libre,
    images,
  } = data.prismicProjet.data;
  // console.log(data.prismicProjet.data);

  const [active, setActive] = useState();
  // const [current, setCurrent] = useState(1);
  // const [length, setLength] = useState(images.length || 0);
  // const [loaded, setLoaded] = useState(false);
  // const collapsed = true;
  const slidesTotal = images.length || 0;

  // const _onChange = () => console.log("_onChange");

  // const columns = ["sta", "cat", "abc", "br", "lieu", "phase", "date"];
  // console.log("Projet", "template-projet");
  return (
    <>
      <SEO
        pageTitle={title.text}
        pageDescription={texte.text}
        pageImage={image_clef.url}
        template="template-single-projet no-scroll"
        page={true}
      />

      <div className="projet-slider app-h">
        <Slider
          size="contain"
          input={images}
          autoplay={false}
          // onChange={_onChange}
        />
      </div>

      <div className={clsx("projet-footer psf b0", active ? "is-active" : "")}>
        <div
          className="summary fS fSeg py-m"
          onClick={() => setActive(!active)}
        >
          <div className="inner">
            <div className="px-l-">
              <div className="x xjb">
                <div className="col-md-6- col-xs-">
                  <ul className="projet-metas x ttu fSeg">
                    <li className="pr-m-">
                      <div className="sta">
                        <span>{sta}</span>
                        <span>{"   "}</span>
                      </div>
                    </li>
                    <li className="pr-s-">
                      <div className="cat">
                        <span>{cat}</span>
                        <span> </span>
                      </div>
                    </li>
                    <li className="pr-m-">
                      <div className="abc">
                        <span>{abc}</span>
                        <span>{"  "}</span>
                      </div>
                    </li>
                    <li className="df hidden-sm">
                      <h1 className="fS ttu ">{title.text}</h1>
                      <span className="">, {lieu}</span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6- col-xs-">
                  <div className="x xjb">
                    <SliderPagination total={slidesTotal} />

                    <div className=" btn-toggle-wrapper pl-m">
                      <div className="btn-toggle--texte">
                        {active ? <span>Image</span> : <span>Texte</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={clsx(
          "detail px-l pt-header-height psf t0 r0 b0 l0 bg-primary",
          active ? "block" : "hidden"
        )}
      >
        <div className="mb-m">
          <div className="row gutter-m">
            <div className="col-md-6 col-xs-12">
              <ul className="fiche-technique fM">
                <li className="row">
                  <div className="col-md-4 col-xs-6 c4- clef fSeg strong- ttu">
                    LIEU
                  </div>
                  <div className="col-md-8 col-xs-6 c8- valeur">{lieu}</div>
                </li>
                <li className="row">
                  <div className="col-md-4 col-xs-6 c4- clef fSeg strong- ttu">
                    PHASE
                  </div>
                  <div className="col-md-8 col-xs-6 c8- valeur">{phase}</div>
                </li>
                <li className="row">
                  <div className="col-md-4 col-xs-6 c4- clef fSeg strong- ttu">
                    DATE
                  </div>
                  <div className="col-md-8 col-xs-6 c8- valeur">{date}</div>
                </li>

                {fiche_technique_libre.map((item, i) => (
                  <li className="row" key={i}>
                    <div className="col-md-4 col-xs-6 c4- clef fSeg strong- ttu">
                      {item.clef}
                    </div>
                    <div className="col-md-8 col-xs-6 c8- valeur">
                      {item.valeur}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="scroll-y">
                <div className="texte fL ">{RichText.render(texte.raw)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withPreview(TemplateProjet);
// export default TemplateProjet;
